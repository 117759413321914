import React from 'react';

import PropTypes from 'prop-types';

import BGArrowPointerBottomMobile from '../../assets/images/bg-page-banner-v2-bottom-mobile.inline.svg';
import BGArrowPointerBottomLeft from '../../assets/images/bg-page-banner-v2-bottom.inline.svg';
import BGArrowPointerTopMobile from '../../assets/images/bg-page-banner-v2-top-mobile.inline.svg';
import BGArrowPointerTopLeft from '../../assets/images/bg-page-banner-v2-top.inline.svg';
import { ShapeYellowArrowBanner } from '../../Atoms/Icons/Icons';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import useMedias from '../../hooks/useMedias';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './PageBannerStyle.scss';
let classNames = require('classnames');

const PageBannerStyle = ({ visuel, alt, breadcrumbData, children }) => {
  const { processUrl } = useMedias();

  return (
    <div className="page_banner_v2">
      <div className="visuel_container">
        <div className="visuel">
          {visuel && (
            <>
              <img
                className="only_desktop"
                src={processUrl(visuel?.image_style_uri?.gatsby_wide)}
                alt={alt || ''}
                style={{ height: '100%', objectFit: 'cover' }}
              />
              <img
                className="only_mobile_tablet"
                src={processUrl(visuel?.image_style_uri?.gatsby_medium)}
                alt={alt || ''}
                style={{ height: '100%', objectFit: 'cover' }}
              />
            </>
          )}
        </div>
        <div className={classNames('visuel_overlay')}>
          <div className="wrapper_page">
            <Breadcrumb page_banner={true} data={breadcrumbData} />
          </div>
        </div>
      </div>
      <div className={classNames('intro_design')}>
        <div className={classNames('shaped_background_banner')}>
          <div className={classNames('bg_arrow_pointer_top')}>
            <BGArrowPointerTopMobile className="only_mobile_tablet" />
            <BGArrowPointerTopLeft className="only_desktop" />
          </div>
          <div className="scroll_icon_container">
            <ScrollIcon />
          </div>
          <div className="center_content">{children}</div>
          <div className={classNames('bg_arrow_pointer_bottom')}>
            <BGArrowPointerBottomMobile className="only_mobile_tablet" />
            <BGArrowPointerBottomLeft className="only_desktop" />
          </div>
        </div>
        <ShapeYellowArrowBanner className="shape_yellow_banner_bottom" />
      </div>
    </div>
  );
};

PageBannerStyle.propTypes = {
  visuel: PropTypes.string.isRequired,
};

export default PageBannerStyle;
