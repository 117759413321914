import React, { useState } from 'react';

import { Link } from 'gatsby';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import ArrowButton from '../../Atoms/ArrowButton/ArrowButton';
import {
  ArrowIconGoRight,
  HorizontalYellowLineShape,
  IconLectureTime,
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import { Button } from '../../Molecules/Button/Button';
import SocialShare from '../../Molecules/SocialShare/SocialShare';

import './Carousel.scss';
import { trackRealisationEvents } from '../../Utils/Tracking';

import { trackCarouselEvent } from '../../Utils/Tracking';
import { trackInnovationEvents } from '../../Utils/Tracking';

let classNames = require('classnames');

const slideWidth = 200;



let position = 0;

const onClickEvent = (pageName, zone, content_name) => {
  console.log(pageName)
  let activity = document.querySelector('.title_intro_page_component .title h1').textContent.split(" ").splice(-1)[0]
  let pageCategory = document.querySelector('.title_intro_page_component .title h1').textContent

  switch (pageName) {
    case "innovation":
      trackInnovationEvents(
        {
          event: 'clic_cta',
          page_name: 'innovation',
          page_type: 'innovation',
          zone: zone,
          button_content: 'decouvrir_le_projet',
          button_type: 'cta',
          content_name: content_name
        }
      )
      break;
    case "nos_activites_n-1":
      trackRealisationEvents({
        'event': 'clic_realisations',
        'page_name': 'nos_activites',
        'activity': activity,
        'page_type': 'groupe',
        'page_category': pageCategory,
        'content_name': content_name,
        'button_content': 'decouvrir_le_projet',
        'button_type': 'cta'
      })
      break;
  }

}

const createItem = (slides, position, idx) => {

  const item = {
    info: slides[idx].info,
    image: slides[idx].image,
    alt: slides[idx].alt,
    reading_time: slides[idx].reading_time,
    category: slides[idx].category || null,
    position: position
  };

  const active_slide = slides.length < 3 ? 1 : 3;
  // console.log("position", item)
  switch (position) {
    case active_slide - 1:
      item.styles = {
        transform: `translateX(${position * slideWidth}px)`,
        width: '740px',
        height: '100%',
      };
      item.class_name = 'active';


      break;
    case active_slide + 1:
    case active_slide + 2:
    case active_slide:
      item.styles = {
        transform: `translateX(${position * slideWidth + 540}px)`,
      };
      item.class_name = '';
      break;

    case active_slide + 3:
      item.styles = {
        transform: `translateX(${position * slideWidth + 540}px)`,
        opacity: 0,
      };
      item.class_name = '';
      break;
    default:
      item.styles = {
        transform: `translateX(${position * slideWidth}px)`,
        opacity: 0,
      };
      item.class_name = '';
      break;
  }

  return item;
};

const CarouselSlideItem = ({ slides, pos, idx, sliderMode, pageName, contentName, locale }) => {
  const { processUrl } = useMedias();

  const item = createItem(slides, pos, idx);
  sliderMode = sliderMode || '';
  return (
    <li
      className={classNames('carousel__slide-item', item.class_name)}
      style={item.styles}
    >
      <div className="carousel__slide-item-img-link">
        {sliderMode === 'articles' && (
          <div className="info_share">
            <p className="lecture_time">
              {item.category !== 8 && (
                <>
                  <IconLectureTime className="time_icon" />
                  <span>{item.reading_time}</span>
                  <HorizontalYellowLineShape className="bottom_line" />
                </>
              )}
            </p>
            {item.category !== 8 && (
              <SocialShare
                title={item.info.text}
                linkTo={item.info.link}
                vertical={true}
                hidemobile={true}
              />
            )}
          </div>
        )}
        <div className="visuel">
          <img
            className="fluid_"
            src={processUrl(item.image?.image_style_uri?.gatsby_medium_carousel)}
            alt={item.alt || ''}
            style={{ height: "100%", width: '100%', objectFit: 'cover' }}
            loading="lazy"
          />
        </div>

      </div>
      <div
        className={classNames('info', 'info_style_' + sliderMode, {
          no_btn:
            sliderMode != 'articles' &&
            (!item.info.link_label || !item.info.link),
        })}
      >
        <p className="title">

          {item.info.link ? (
            <>
              {item.info.link.indexOf('.pdf') > 0 ? (
                <a target="_blank" href={`${process.env.GATSBY_METADATA_SITE_URL}${locale}/pdf/?file=https:${item.info.link}&title=${item.info?.text}`}>
                  {item.info.text}{' '}
                  {sliderMode === 'articles' && (
                    <ArrowIconGoRight fill="#ffffff" />
                  )}
                </a>
              ) : (
                <Link to={item.info.link} >
                  {item.info.text}{' '}
                  {sliderMode === 'articles' && (
                    <ArrowIconGoRight fill="#ffffff" />
                  )}
                </Link>
              )}
            </>
          ) : (
            item.info.text
          )}
        </p>
        {item.info.description && <p>{item.info.description}</p>}
        {sliderMode != 'articles' && item.info.link_label && item.info.link && (
          <Button
            primary={true}
            label={item.info.link_label}
            link={
              item.info.link +
              ((item.info.anchor !== undefined && item.info.anchor !== null) ? item.info.anchor : '')
            }
            arial-label={item.info.link_label + ' - ' + item.info.text}
            onClickEvent={() => onClickEvent(pageName, contentName, item.info.text)}
          />
        )}
      </div>
    </li>
  );
};

const Carousel = ({ slides, sliderMode, textColor, contentName, pageType, pageName }) => {
  const intl = useIntl();
  const _slides = slides;
  const keys = Array.from(Array(_slides.length).keys());
  const [items, setItems] = React.useState(keys);
  const bigLength = items.length;
  let trackingZone
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const prevClick = (jump = 1) => {
    trackingZone = document.querySelector('.title_text h2').textContent
    position = position - 1;
    let previousSibling = document.querySelector('.carousel__slide-item.active').previousElementSibling;
    let currContent2 = ""

    if (previousSibling) {
      currContent2 = previousSibling.querySelector('.title a') ? previousSibling.querySelector('.title a').textContent : previousSibling.querySelector('.title').textContent;
    }

    if (position <= 0) {
      position = slides.length
    }
    trackCarouselEvent('clic_fleche_carroussel', pageName, pageType, position, 'prec.', currContent2, 'previous_icon', contentName)
    setItems((prev) => {
      const newIndex = (currentSlideIndex - + jump) % bigLength;
      setCurrentSlideIndex(newIndex);
      return prev.map((_, i) => prev[(i + jump) % bigLength]);
    });

  };

  const nextClick = (jump = 1) => {
    position = position + 1;
    if (position > slides.length) {
      position = 1
    }

    let nextSibling = document.querySelector('.carousel__slide-item.active').nextElementSibling;
    let currContent = ""
    if (nextSibling) {
      currContent = nextSibling.querySelector('.title a') ? nextSibling.querySelector('.title a').textContent : nextSibling.querySelector('.title').textContent;
    }

    trackCarouselEvent('clic_fleche_carroussel', pageName, pageType, position, 'suiv.', currContent, 'next_icon', contentName)
    setItems((prev) => {
      const newIndex = (currentSlideIndex - jump + bigLength) % bigLength;
      setCurrentSlideIndex(newIndex);
      return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
    });
  };

  return (
    <div
      className={classNames('carousel__wrap', 'slider_mode_' + sliderMode, {
        no_slider: bigLength < 3,
      })}
    >
      <div className="carousel__inner">
        <div className="carousel__container">
          <ul
            className="carousel__slide-list"
            style={{
              width: 740 + (_slides.length - 1) * slideWidth + 10 * 20 + 'px',
            }}
          >
            {items.map((pos, i) => (

              <CarouselSlideItem
                slides={_slides}
                key={i}
                idx={i}
                pos={pos}
                sliderMode={sliderMode}
                contentName={contentName}
                pageType={pageType}
                pageName={pageName}
                locale={intl.locale}
              />
            ))}
          </ul>
        </div>

        <ArrowButton
          direction="prev"
          callback={prevClick}
          text={intl.formatMessage({
            id: 'carrousel.prev',
          })}
          textColor={textColor}
        />
        <ArrowButton
          direction="next"
          callback={nextClick}
          text={intl.formatMessage({
            id: 'carrousel.next',
          })}
          textColor={textColor}
        />
      </div>
    </div>
  );
};

export default Carousel;
